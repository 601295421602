<template>
  <div class="adddialog">
    <el-dialog
      class="dialog"
      :visible="iframeDialog"
      width="80%"
      min-width="600px"
      :before-close="del"
      :close-on-click-modal="false"
    >
       <iframe   id="FrameID"
         style="border:none"
        
         :src="resulturl"
       ></iframe>
      
    </el-dialog>
  </div>
</template>
<script>
import { log } from "../../utils/EMedia_x1v1_3.4.1";

var vm= {
  data() {
    return {
    }
  },
  props: {
    iframeDialog: {
      type: Boolean,
      default: false,
    },
    resulturl:"",
   
  },
  created(){

  },
  methods: {
 
    handleMessage(){
      this.del() 
    },
    del() {
     
    if(this.$store.state.home.iframestate=="withdraw"){
      this.$parent.getBalDetail()
     
    }
    if(this.$store.state.home.iframestate=="Updateemail"||this.$store.state.home.iframestate=="Updatepass"){
      //  this.$parent.getPaymentconfigInfo()
        // this.$router.push("/home/admin")
        // this.$router.go(0)
    }
    this.$emit("del", false);
    },
  },
};
export default vm;
</script>
<style lang="scss" scoped>
iframe{ 
  width: 100%;
  height: 650px;
}

</style>