<template>
  <div class="deal">
    <div class="top">
      <p><span :class="{ active: isActive }" @click="codee">买入</span></p>
      <p><span :class="{ active: Active }" @click="uncode">卖出</span></p>
    </div>
    <div class="buying" v-if="type == 1"></div>
    <div class="sale" v-if="type == 2"></div>
    <!-- 流程导航 -->
    <div class="item">
      <div
        v-for="(tabBar, index) in date"
        :key="index"
        class="a"
        :class="{ active: tabIndex == tabBar.id }"
        @click="toggleTab(tabBar.id)"
      >
        {{ tabBar.name }}
      </div>
    </div>
    <div class="box">
      <div class="publicItem" v-for="(data, index) in detailsList" :key="index">
        <div class="contentTop">
          <div class="imgBox">
            <viewer> <img :src="data.image" alt="" /></viewer>
          </div>
          <div class="msgBox">
            <div class="goodss">
              <h2 class="title">{{ data.goodsname }}</h2>
              <div class="weightBox">
                ￥<span class="weight">{{ data.goodsprice }}</span
                >/吨
              </div>
            </div>
            <div class="priceaa">
              合计：<span class="total">￥{{ data.goodstotalprice }}</span
              >数量：<span class="amount">{{ data.goodsnumber }}</span
              >实付金额：￥<span class="money">{{ data.paytotalprice }}</span>
              <p v-if="tabIndex == 1" v-show="type == 1">
                <span>*为确保交易安全请尽量选择线上交易</span
                >
                <!-- <span>*选择线上交易平台将会收取百分之5的服务费</span> -->
              </p>
            </div>
          </div>
          <div class="stateBox">
            <div class="state" v-show="tabIndex == 0"><span>待确认</span></div>
            <div class="state" v-show="tabIndex == 1" v-if="type == 1">
              <span>已签署</span>
              <p>付款截止时间：{{ data.newcreate }}</p>
            </div>

            <div class="state" v-if="tabIndex == 2"><span>已拒绝</span></div>
            <div class="state" v-if="tabIndex == 3"><span>线上交易</span></div>
            <div class="state" v-if="tabIndex == 4"><span>线下交易</span></div>
            <a
              class="online"
              @click="offlinpay(data.orderCode)"
              v-if="tabIndex == 1"
              v-show="type == 1"
              >线下支付</a
            >
          </div>
        </div>
        <div class="contentBottom">
          <h3 class="companyBox">
            企业信息<span class="company" v-show="type == 1">{{
              data.sellername
            }}</span>
            <span class="company" v-show="type == 2">{{ data.buyername }}</span>
          </h3>
          <div class="btn">
            <div class="emitMsg" @click="WannaTalk(data.sellerid)">
              发起会话
            </div>
            <router-link
              :to="{
                path: '/OrderDetial',
                query: { id: tabIndex,type:type, ordercode: data.orderCode },
              }"
              tag="div"
              class="emitMsg"
              >查看详情
            </router-link>
            <div
              class="emitMsg sure"
              v-if="tabIndex != 2"
              @click="surecontract(data.signurl)"
            >
              {{ tabIndex == 0 ? "确认合同" : "查看合同" }}
            </div>
            <div
              class="emitMsg sure"
              v-if="tabIndex == 1"
              v-show="type == 1"
              @click="continuepay(data.orderCode)"
            >
              继续支付
            </div>
          </div>
          <Commoditymessage
            @clears="clears"
            :WannaTalkdialog="WannaTalkdialog"
            :CompanyUserList="CompanyUserList"
            @surees="surees"
          ></Commoditymessage>
        </div>
        <el-dialog
          class="el-radiosect"
          :visible.sync="paydialogVisible"
          width="30%"
        >
          <div class="radiosect">
            <el-radio-group v-model="payId" @change="onRadioChange">
              <el-radio
                :value="item.code"
                :label="item.code"
                v-for="item in PayList"
                :key="item.code"
                >{{ item.name }}</el-radio
              >
            </el-radio-group>
          </div>
          <div class="radiosec">
            <el-button @click="paydialogVisible = false">取 消</el-button>
            <el-button type="danger" @click="paysure"
              >确 定</el-button
            >
          </div>
        </el-dialog>

           <el-dialog
          class="el-radiosect"
          :visible.sync="payfinsh"
          width="30%"
          :before-close="payfinshok"
        >
          <div class="payradiosect">
             是否已经完成支付
          </div>
          <div class="radiosec">
            <el-button @click="payfinshok">已完成</el-button>
            <el-button type="danger" @click="paysurenext"
              >继续支付</el-button
            >
          </div>
        </el-dialog>
      
        <Iframe
          :iframeDialog="iframeDialog"
          ref="iframe"
          :resulturl="resulturl"
          @del="del"
        ></Iframe>
      </div>
     
    </div>
     <!-- 分页 -->
      <!-- <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-size="pageSize"
        layout="prev, pager, next"
        :total="totalCount"
      >
      </el-pagination> -->
       <Pagination class="el-pagination " :num="totalCount" ref="changepage" :limit="pageSize" :totalPageCount="totalPageCount"></Pagination>
  </div>
</template>
<script>
import { Message, MessageBox } from "element-ui";
import tradingApi from "../../api/tradingApi";
import loginApi from "../../api/loginApi";
import Iframe from "../center/iframe.vue";
import imApi from "../../api/imApi";
import Pagination from "@/components/prePage/pagination.vue";
import Commoditymessage from "../../components/chat/customer.vue";
import { log } from "../../utils/EMedia_x1v1_3.4.1";
import applocationCfg from "../../utils/applocationCfg"
export default {
  data() {
    return {
      payfinsh:false,
      // 创建时间
      createTime: "",
      // 合同签署时间
      contracttime: "",
      payId: "",
      resulturl: "",
      arrcreateTime: [],
      WannaTalkdialog: false,
      iframeDialog: false,
      paydialogVisible: false,
      // ncodesure:true,
      detailsList: [],
      isActive: true,
      Active: false,
      type: 1,
      pageNum: 1,
      pageSize: 3,
      // 总数
      totalCount: 0,
      // 总页数
      totalPageCount: 0,
      indexordercode:"",
      tabIndex: 0,
      // 银行类表
      PayList: [],
      eorderCode: "",
      CompanyUserList: [],
      date: [
        { name: "待确认", id: "0" },
        { name: "已签署", id: "1" },
        { name: "已拒绝", id: "2" },
        { name: "线上交易", id: "3" },
        { name: "线下交易", id: "4" },
      ],
    };
  },
  created() {
    setTimeout(this.getBuyOrderList(), 100);
    
  },
  components: {
    Commoditymessage,
    Iframe,
    Pagination
  },
  methods: {
            change(even){
 this.pageNum = even
    if (this.type == 1) {
        this.getBuyOrderList();
      }
      // 出售订单
      else {
        this.getSelOrderList();
      }
    },
    payfinshok(){
      this.payfinsh = false
       this.getBuyOrderList();
    },
    clears(bool) {
      this.WannaTalkdialog = bool;
    },
    surees(bool) {
      this.WannaTalkdialog = bool;
    },
    // 发起会话
    WannaTalk(companyId) {
      var islogin = this.checkLogin();
   
      if (!islogin) return;

      imApi.getCompanyUserList({ companyId: companyId }, (res) => {
        this.CompanyUserList = res.data.result;
        this.WannaTalkdialog = true;
      
      });
    },
    checkLogin() {
      let isLogin = localStorage.getItem("Login_data");
      if (!isLogin) {
        Message.error("请先登录");
        return false;
      } else {
        loginApi.checkLogin("", (res) => {
          if (res.data.code == 200) {
            if (!res.data.result) {
              Message.error("请先登录");
              return false;
            }
          }
        });
      }
      if (WebIM.isLogin) return true;
      imApi.getCurrUser("", (res) => {
        if (res.data.code == 200) {
          var options = {
            user: res.data.result.username,
            pwd: res.data.result.password,
            appKey: WebIM.config.appkey,
            apiUrl: WebIM.config.Host,
          };
          WebIM.conn.open(options);
          return true;
        } else if (res.data.code == 401) {
          Message.error("请先进行个人认证");
          return false;
        }
      });
    },
    del(bool) {
      this.resulturl = "";
      this.addDialog = bool;
      this.iframeDialog = bool;
    },
    //  获取银行id
    onRadioChange(item) {
   
      this.payId = item;
    },
    // 继续支付
    continuepay(ordercode) {
      this.indexordercode = ordercode
      this.paydialogVisible = true;
      // 获取银行列表
      tradingApi.getPayList("", (res) => {
        if (res.data.code == 200) {
          this.PayList = res.data.result;

        } else {
          Message.error(res.data.message);
        }
      });
    },
    paysurenext(){
       let filedValue =  this.lastfiledValue;
              window.open(
              applocationCfg.getUrl()+`/pay?k=${encodeURIComponent(filedValue)}`
              
            );
    },
    // 确认继续支付
    paysure() {
      tradingApi.applyPayUrl(
        { orderCode: this.indexordercode, payId: this.payId },
        (res) => {
        
          if (res.data.code == 200) {
           window.open(
              applocationCfg.getUrl()+`/pay?k=${encodeURIComponent(res.data.result)}`
            );
            this.paydialogVisible = false
            this.payfinsh = true
            this.lastfiledValue=res.data.result;
          
          } else {
            Message.error(res.data.message);
          }
        }
      );
    },
    // 设置线下支付
    offlinpay(eorderCode) {
      MessageBox.confirm("此操作订单将转为线下支付, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          tradingApi.setOfflinePay({ ordercode: eorderCode }, (res) => {
            if (res.data.code == 200) {
              Message.success(res.data.message);
              this.getBuyOrderList();
            } else {
              Message.error(res.data.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 确认合同
    surecontract(e) {
     
      this.iframeDialog = true;
      this.resulturl = e;
    },
    // 买入
    codee() {
      this.type = 1;
      this.Active = false;
      this.isActive = true;
      this.getBuyOrderList();
    },
    //卖出
    uncode() {
      this.type = 2;
      this.Active = true;
      this.isActive = false;
      this.getSelOrderList();
    },
    // 买入订单
    getBuyOrderList() {
      tradingApi.getBuyOrderList(
        {
          state: this.tabIndex,
          pageNum: this.pageNum, 
           
          pageSize: this.pageSize,
        },
        (res) => {
          if (res.data.code == 200) {
            this.detailsList = res.data.result.data;
            this.totalCount = res.data.result.totalCount;
            this.totalPageCount = res.data.result.totalPageCount;
          
            if (this.tabIndex == 1 && this.type == 1) {
              for (let index = 0; index < this.detailsList.length; index++) {
                const timeorderCode = this.detailsList[index].orderCode;
                // 获取订单详情
                tradingApi.getOrderDetails(
                  { ordercode: timeorderCode },
                  (res) => {
                    if (res.data.code == 200) {
                      this.indentdetial = res.data.result;

                      this.createTime = res.data.result.createTime;
                      if (this.arrcreateTime.length > 0) {
                        if (this.createTime != this.arrcreateTime[index]) {
                          this.arrcreateTime.push(this.createTime);
                        }
                      } else {
                        this.arrcreateTime.push(this.createTime);
                      }
                    }
                  }
                );
              }
           
              for (let index = 0; index < this.arrcreateTime.length; index++) {
                const element = this.arrcreateTime[index];

                var d1 = new Date(element);
                var a = d1.valueOf();
                var h = 4; //h為小時數
                a = a + h * 3600 * 1000; //可加可減
                a = new Date(a);
                var str =
                  a.getFullYear() +
                  "/" +
                  (a.getMonth() + 1) +
                  "/" +
                  a.getDate() +
                  " ";
                str +=
                  a.getHours() + ":" + a.getMinutes() + ":" + a.getSeconds();
                this.detailsList[index].newcreate = str;
              }
            }
          }
        }
      );
      this.$forceUpdate();
    },
    // 出售订单
    getSelOrderList() {
      tradingApi.getSelOrderList(
        {
          state: this.tabIndex,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        },
        (res) => {
          this.detailsList = res.data.result.data;
          this.totalCount = res.data.result.totalCount;
          this.totalPageCount = res.data.result.totalPageCount;
          //              if(this.tabIndex==2&&this.type == 2){
          //         for (let index = 0; index <  this.detailsList.length; index++) {
          //             const timeorderCode =  this.detailsList[index].orderCode;
          //              console.log(timeorderCode);
          //               // 获取订单详情
          //          tradingApi.getOrderDetails({ ordercode: timeorderCode }, (res) => {
          //         if (res.data.code == 200) {
          //           // this.indentdetial = res.data.result;
          //           this.createTime = res.data.result.createTime
          //           if(this.createTime!=timeorderCode){
          //               this.arrcreateTime.push(this.createTime)
          //           }
          //         }
          //       });

          //           }
          //             console.log(this.arrcreateTime);
          //       for (let index = 0; index <  this.arrcreateTime.length; index++) {
          //      const element =  this.arrcreateTime[index];
          //      console.log(element);
          //      var d1=new Date(element);
          // var a=d1.valueOf();
          // var h=4; //h為小時數
          // a=a+(h*3600*1000); //可加可減
          // a=new Date(a);
          // var str=a.getFullYear()+'/'+(a.getMonth()+1)+'/'+a.getDate()+' '
          // str+=a.getHours()+':'+a.getMinutes()+':'+a.getSeconds();
          // // alert(str);

          // this.detailsList[index].newcreate =str
          // console.log(this.detailsList[index].newcreate);
          //             }
          //           }
        }
      );
    },
    // 流程图table
    toggleTab(index) {
      this.tabIndex = index;
       this.pageNum=1
         this.$refs.changepage.pageOffset(1)
        //    this.getCollectRecordList()
        this.change(1)
      // 购买订单记录
      if (this.type == 1) {
        this.getBuyOrderList();
        setTimeout(() => {
          this.getBuyOrderList();
        }, 100);
      }
      // 出售订单
      else {
        this.getSelOrderList();
        setTimeout(() => {
          this.getSelOrderList();
        }, 100);
      }
    },
    //监听pagesize改变事件
    handleSizeChange(newSize) {
      this.pageSize = newSize;
      if (this.type == 1) {
        this.getBuyOrderList();
      }
      // 出售订单
      else {
        this.getSelOrderList();
      }
    },
    //监听pageNum改变事件
    handleCurrentChange(newPage) {
      this.pageNum = newPage;
      if (this.type == 1) {
        this.getBuyOrderList();
      }
      // 出售订单
      else {
        this.getSelOrderList();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  .el-pagination {
      // border: 1px solid red;
      width: 100% !important;
      margin-top: 20px;
    }
.online {
  cursor: pointer;
  text-align: center;
  border: 1px solid rgb(233, 79, 85);
  border-radius: 5px;
  width: 100px;
  height: 30px;
  background-color: transparent;
  line-height: 30px;
  color: rgb(233, 79, 85);
  margin-left: 10px;
  margin-bottom: 5px;
}
.payradiosect{
  width: 200px;
  height: 200px;
  margin: 0 auto;
  font-size: 16px;
  text-align: center;

}
.el-radio {
  // border: 1px solid red;
  padding: 10px;
}

.radiosec {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 20px auto;
}
::v-deep .el-radiosect .el-dialog {
  height: 400px !important;
}
::v-deep .lookcontent {
  height: 750px !important;
}
::v-deep .lookcontent .el-dialog__body {
  height: 650px;
}
iframe {
  width: 100%;
  height: 100%;
}
.deal {
  // border: 1px solid red;
  width: 60%;
  cursor: pointer;
  margin-top: -10px;
  min-width: 700px;
  .active {
    color: #e94f55;
  }
  .top {
    width: 50%;
    margin: 0px auto;
    display: flex;
    p:nth-child(1) {
      border-right: 1px solid #ccc;
    }
    p {
      width: 50%;
    }
    span {
      width: 20%;
      margin: 0 60px;
      font-size: 16px;
      display: inline-block;
      text-align: center;
      padding: 5px;
    }
  }
  .buying,
  .sale {
    width: 70%;
    //  border: 1px solid red;
    margin: 0 auto;
    font-size: 18px;
    //  padding-top:100px;
  }
  .item {
    width: 60%;
    //  border: 1px solid red;
    margin: 20px auto;
    display: flex;
    font-size: 16px;
    justify-content: space-between;
  }
  .box {
    width: 100%;
    min-height: 590px;
    margin: 0 auto;
    // position: relative;
  

    .publicItem {
      width: 100%;
      display: flex;
      flex-direction: column;

      padding: 5px 10px;
      background-color: white;
      box-sizing: border-box;
      margin-bottom: 10px;
      .contentTop {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #bbb;
        .imgBox {
          img {
            width: 100px;
            height: 100px;
            border-radius: 10px;
          }
        }
        .msgBox {
          padding: 0 20px;
          display: flex;
          flex-direction: column;
          .goodss {
            .title {
              font-size: 16px;
              font-weight: 600;
              margin-bottom: 10px;
            }
            .weightBox {
              .weight {
                font-size: 20px;
              }
            }
          }
          .priceaa {
            width: 400px;
            padding-top: 20px;
            font-size: 14px;
            p {
              font-size: 12px;
            }
            span {
              color: rgb(116, 114, 114);
              padding-right: 15px;
              border: 0;
            }
            .money {
              font-weight: 600;
              font-size: 18px;
              color: black;
            }
          }
        }
        .stateBox {
          // flex: 1;
          width: 130px;
          height: 120px;
          text-align: center;
          // border: 1px solid red;
          padding-right: 20px;
          position: relative;
          a {
            position: absolute;
            bottom: 0;
            left: 18%;
            color: rgb(233, 79, 85);
          }
          .state {
            width: 100%;
            // border: 1px solid red;
            span {
              color: rgb(233, 79, 85);
              text-align: right;
              font-size: 16px;
            }
            p {
              font-size: 12px;
              color: rgb(233, 79, 85);
              margin-top: 5px;
            }
          }
        }
      }
      .contentBottom {
        flex: 1;
        display: flex;
        justify-content: space-between;
        padding-top: 10px;
        .companyBox {
          font-size: 14px;
          height: 30px;
          line-height: 30px;
          position: relative;
          width: 230px;
          overflow: hidden; /*超出部分隐藏*/
          white-space: nowrap; /*不换行*/
          text-overflow: ellipsis; /*超出部分文字以...显示*/
          span {
            margin-left: 15px;
          }
          .company {
            font-weight: normal;
            // border: 1px solid red;
          }
        }
      }
      .btn {
        display: flex;
        .emitMsg {
          cursor: pointer;
          text-align: center;
          border: 1px solid rgb(233, 79, 85);
          border-radius: 5px;
          width: 100px;
          height: 30px;
          background-color: transparent;
          line-height: 30px;
          color: rgb(233, 79, 85);
          margin-left: 10px;
        }
        .sure {
          background-color: rgb(233, 79, 85);
          color: white;
        }
      }
    }
  }
}
</style>