<template>
  <el-dialog :visible.sync="WannaTalkdialog" width="40%"  :before-close="clears">
     <div class="containertop" v-if="centerimgtop">
     
        <div class="containerimg"> <img src="../../assets/index/show.png" class="img" alt=""> <p>线下支付可能勋在交易风险，请选择线上交易。</p></div> 
        <!-- <img src="../../assets/index/clear.png" class="cimg" alt="" @click="imgcenter"> -->
    </div>
    <div class="custom">
      <!-- {{CompanyUserList}} -->
      <div   v-show="userlist">
        <div  
          
          class="customitem"
          v-for="(item, index) in this.CompanyUserList"
          :key="index"
        >
          <img v-if="item.image" :src="item.image" alt="" />
          <img
            v-else
            class="avatar"
            height="50"
            width="50"
            src="../../assets/home/nullimage.png"
          />
          <p>{{ item.nickname ? item.nickname : "未设置昵称" }}</p>
          <el-button type="danger" @click="companyUserdilog(item)">聊一聊</el-button>
        </div>
      </div>
 
        <Message
          v-show="!userlist"
          :type="activeKey"
          :broken="broken"
          :hideUserList="hideUserList"
          :showUserList="showUserList"
          ref="messageList"
          @show_add_member_modal="show_add_member_modal"
        />
     
    </div>
  </el-dialog>
</template>
<script>
import Vue from "vue";
import MessageBox from "../../components/chat/index.vue";
import Message from "../../components/chat/message.vue";
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      activedType: {
        contact: "",
      },
      centerimgtop:true,
      userlist:true,
      contactRead: false,
      showSettingOptions: false,
      activeKey: "contact",
      selectedItem: "",
      showAddOptions: false,
      nowIsVideo: false,
      userName:
        localStorage.getItem("userInfo") &&
        JSON.parse(localStorage.getItem("userInfo")).userid,
      head_portraitImg: require("../../assets/headPortrait.jpeg"),
      collapsed: false,
      broken: false,
      current: ["contact"],
      nowClickID: "",
      showAlert: false,
      comyuser:{},
    };
  },
  components: {
    MessageBox,
    Message,
  },
  props: {
    CompanyUserList: [],
    WannaTalkdialog: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    
  },
  methods: {
    clears() {
     
      this.$emit("clears", false);
        this.userlist = true
    },
    companyUserdilog(val) {
      //this.$parent.$parent.messageWannaopen() 
      var myid =JSON.parse( localStorage.getItem("userInfo"))
      if(myid==null||myid==""){
				return;
			}
      localStorage.setItem("msguser_"+myid.userid+"_"+val.userid,  val.userid);
      this.userlist = false
       this.comyuser.image =val.image
         this.comyuser.name =val.userid
         this.comyuser.nicknamer =val.nickname
      this.select(this.comyuser);
      setTimeout(()=>{
         this.select(this.comyuser);
      },300)
    },
    ...mapActions([
      "onLogout",
      "onGetFirendBlack",
      "initChatState",
      "updateConfr",
      "setCallStatus",
      "hangup",
      "cancelCall",
      "onGetContactUserList",
    ]),
    onCollapse(collapsed, type) {
      if (type != "responsive") {
        this.$data.collapsed = true;
      } else {
        this.$data.collapsed = false;
      }
    },
    onBreakpoint(broken) {
      this.$data.broken = broken;
    },
    show_add_member_modal() {
      this.$refs.addAvMembertModal.show();
    },
    hideUserList() {
      this.$data.collapsed = true;
    },
    showUserList() {
      this.$data.collapsed = false;
    },
    select(i) {
      this.$refs.messageList.select(i);
      //
       
      if (this.broken) {
       this.$data.collapsed = true;
      }
      setTimeout(()=>{
         this.$refs.messageList.select(i);
      //
      if (this.broken) {
       this.$data.collapsed = true;
      }
      },300)
    },
  },
  computed: {
    userDetail() {
      return this.$store.state.home.userDetail;
    },
    chatList() {
      return this.$store.state.chat.msgList;
    },
    onSetCallStatus() {
      return this.$store.state.agora.callStatus;
    },
  },
};
</script>
<style lang="scss" scoped>
.containertop{
  // width: 30px;
  line-height: 30px;
  height: 30px;
display: flex;
justify-content: space-between;
padding: 2px 10px;
align-items: center;
background-color: rgb(253,249,237);
.containerimg{
  font-size: 12px;
  display: flex;
justify-content: space-between;
// padding: 2px 10px;
align-items: center;
 color: rgb(255,131,66);
}
.img{
  margin-right: 5px;
 
  vertical-align: middle;
  width: 20px;
  height: 20px;
}
.cimg{
  width: 10px;
  height: 10px;
}
}
.custom {
  //    border: 1px solid red;
  width: 100%;
  height: 500px;
  .customitem {
    cursor: pointer;
    width: 60%;
    // border: 1px solid red;
    margin: 0 auto;
    border-bottom: 1px solid #ccc;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    margin-top: 10px;
    p {
      margin-left: 10px;
      margin-right: 20px;
      // width: 150px;
    }

    img {
      width: 50px;
      height: 50px;
    }
  }
}
::v-deep .el-dialog__header{
  padding: 0;
}
::v-deep .el-dialog__body{
  padding: 0;
}
</style>