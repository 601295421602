import baseApi from './baseApi.js';
const tradingApi = {
	// 创建订单（发起合同）
	createOrder(params,callback){
		baseApi.post('trading/order/createOrder',params,callback)
	},
	// 申请支付
	applyPayUrl(params,callback){
		baseApi.post('trading/order/applyPayUrl?orderCode=' + params.orderCode + "&payId=" + params.payId,null, callback)
	},
	// 获取购买订单记录
	getBuyOrderList(params,callback){
		baseApi.get('trading/order/getBuyOrderList?state='+params.state + "&pageNum="+ params.pageNum+ "&pageSize="+ params.pageSize, null,callback)
	},
	// 获取出售订单记录
	getSelOrderList(params,callback){
		baseApi.get('trading/order/getSelOrderList?state='+params.state+ "&pageNum="+ params.pageNum+ "&pageSize="+ params.pageSize,null,callback)
	},
		// 获取订单详情
		getOrderDetails(params,callback){
			baseApi.get('trading/order/getOrderDetails?ordercode='+params.ordercode,null,callback)
		},
		// 获取订单商品详情
		goodsDetails(params,callback){
			baseApi.get('trading/order/goodsDetails?ordercode='+params.ordercode,null,callback)
		},
		// 获取企业简介
		getCompanyBriefInfo(params,callback){
			baseApi.get('center/company/search/getCompanyBriefInfo?companyId='+params.companyId,null,callback)
		},
			// 设置线下支付
			setOfflinePay(params,callback){
				baseApi.post('trading/order/setOfflinePay?ordercode='+params.ordercode,null,callback)
			},
				// 获取银行列表
			getPayList(params,callback){
			baseApi.get('trading/order/getPayList',params,callback)
		},
		// 当前用户修改邮箱
		updateEmail(params,callback){
			baseApi.post('center/paymentconfig/updateEmail',params,callback)
		},
			// 当前用户修改密码
			updatePayPasswordPC(params,callback){
				baseApi.post('center/paymentconfig/updatePayPasswordPC',params,callback)
			},
			// 重发邮件
			sendBindCardEmail(params,callback){
				baseApi.post('center/paymentconfig/sendBindCardEmail',params,callback)
			},
			// 下载合同
			downloadContract(code,callback){
				baseApi.base.download('center/signContract/downloadContract?contractCode='+code,null,callback)
			},
			
}
//抛出api 
export default tradingApi